/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactGA from 'react-ga';
import toast from 'react-hot-toast';
import dompurify from 'dompurify';
import {
  clickEventDescription,
  ga4ClickEvent,
  removeSearchParam
} from '../utils/helper';
import { customerFeed, calendarDates } from '../utils/entity_mapping';
import {
  postConcernHandler,
  googleSearchHandler,
  resetGoogleSearchHandler
} from '../service';
import DatePicker from 'react-datepicker';
import { strings } from '../utils/localizedStrings';

class Issue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: 0,
      screen: 1,
      customerFeed,
      isAltNumber: false,
      isSubmit: false,
      dateIndex: -1,
      dateMeta: [],
      preferred_date: null,
      isUserDate: false,
      isRejected: null,
      hideCommentsNDRFeedback:
        this.props?.tracking?.theme?.meta_data?.udf?.find(
          item => item.key === 'hideCommentsNDRFeedback'
        )?.value ?? false,
      showDatePicker: true
    };
    // this.handleClick = this.handleClick.bind(this);
    this.getFields = this.getFields.bind(this);
    this.getHeading = this.getHeading.bind(this);
    this.renderScreen = this.renderScreen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.searchLandmark = this.searchLandmark.bind(this);
    this.selectedLandmark = this.selectedLandmark.bind(this);
  }
  componentWillReceiveProps() {
    const defaultCustomerFeed = customerFeed;
    const { customerFeed, user, tracking, opsFeed } = this.props;
    if (opsFeed.issue_id) {
      this.setState({ customerFeed: customerFeed || defaultCustomerFeed });
      // hopscotch check for not showing reject screen first, if issue_id is 6.
      if (
        (user.subdomain === 'hopscotch' ||
          user.subdomain === 'hopscotch-test') &&
        opsFeed.issue_id === 6
      ) {
        this.setState({ screen: 2 });
      }
    }
    if (!tracking.order.isFailed && !tracking.order.everFailed) {
      this.goBack();
    }
  }

  componentDidMount() {
    this.setState({ customerFeed });
    if (this.props.opsFeed.issue_id) {
      this.setState({ customerFeed: this.props.customerFeed || customerFeed });
      if (this.props.page.issueId) {
        this.setState({ form: this.props.page.issueId, screen: 2 });
      }
      // hopscotch check for not showing reject screen first, if issue_id is 6.
      if (
        (this.props.user.subdomain === 'hopscotch' ||
          this.props.user.subdomain === 'hopscotch-test') &&
        this.props.opsFeed.issue_id === 6
      ) {
        this.setState({ screen: 2 });
      }
    }
    if (
      !this.props.tracking.order.isFailed &&
      !this.props.tracking.order.everFailed
    ) {
      this.goBack();
    }
    const { ndr_reattempt_day_limit } = this.props.tracking.theme.meta_data;
    // CalendarDates skipping the first day of the calendar, adding 1 more day to the date iterator explicitly
    const dateMeta = calendarDates({
      itr: ndr_reattempt_day_limit + 1,
      subdomain: this.props.user.subdomain
    });
    this.setState({ dateMeta });
    const { doNotCaptureDeliveryDateNDRBuckets } = this.props.customerFeed;
    const { bucket_id } = this.props.opsFeed;
    const showDatePicker =
      doNotCaptureDeliveryDateNDRBuckets?.length === 0 ||
      !doNotCaptureDeliveryDateNDRBuckets?.includes(bucket_id);
    this.setState({ showDatePicker });
  }

  componentDidUpdate(prevProps) {
    const nextPathname = this.context.router.history.location.pathname;
    if (prevProps.location.pathname !== nextPathname) {
      if (this.props.opsFeed.issue_id) {
        this.setState({
          customerFeed: this.props.customerFeed || customerFeed
        });
        // hopscotch check for not showing reject screen first, if issue_id is 6.
        if (
          (this.props.user.subdomain === 'hopscotch' ||
            this.props.user.subdomain === 'hopscotch-test') &&
          this.props.opsFeed.issue_id === 6
        ) {
          this.setState({ screen: 2 });
        }
      }
    }
  }

  handleClick(e, no) {
    let {
      form,
      isRejected,
      isAltNumber,
      customerFeed,
      preferred_date
    } = this.state;
    if (no === 3) {
      isRejected = isAltNumber = null;
      customerFeed.preferred_date = preferred_date = null;
    }
    this.setState({
      screen: no,
      form: no === 1 || no === 2 ? 0 : form,
      isRejected,
      isAltNumber,
      customerFeed,
      preferred_date
    });
    if (no === 2) {
      ga4ClickEvent({
        category: 'CaptureClick',
        action: '0',
        label: clickEventDescription(0),
        props: this.props
      });
      ReactGA.event({
        category: 'CaptureClick',
        action: '0',
        label: clickEventDescription(0)
      });
    }
    e.preventDefault();
  }

  handleRadio(e, i) {
    this.setState({ form: i });
    ga4ClickEvent({
      category: 'CaptureClick',
      action: i.toString(),
      label: clickEventDescription(i),
      props: this.props
    });
    ReactGA.event({
      category: 'CaptureClick',
      action: i.toString(),
      label: clickEventDescription(i)
    });
  }

  goBack = () => {
    const { routePrefix } = this.props.user;
    this.props.history.push(
      `${routePrefix ? `/${routePrefix}` : ''}/${this.props.history.location
        .search + this.props.history.location.hash}`
    );
  };

  getHeading(no) {
    const { lang } = this.props.user;
    switch (no) {
      case 4:
        return (
          <Fragment>
            <p>{strings[lang].fdForm.phone.a}</p>
            <p className="mrg-top-10">
              {strings[lang].fdForm.phone.b(
                ['rblbank', 'rblbank-test'].includes(this.props.user.subdomain)
              )}
            </p>
          </Fragment>
        );
      case 1:
        return <p>{strings[lang].fdForm.address}</p>;
      case 2:
        return (
          <Fragment>
            <p>{strings[lang].fdForm.timing.a}</p>
            <p className="mrg-top-10">{strings[lang].fdForm.timing.b}</p>
            {this.state.showDatePicker && (
              <p className="mrg-top-10">{strings[lang].fdForm.timing.c}</p>
            )}
          </Fragment>
        );
      case 3:
        return (
          <p>
            {strings[lang].fdForm.other(
              ['rblbank', 'rblbank-test'].includes(this.props.user.subdomain)
            )}
          </p>
        );
      case 6:
        return <p>{strings[lang].fdForm.reject}</p>;
      default:
    }
  }

  onCalendarChange = date => {
    let { customerFeed } = this.state;
    customerFeed.preferred_date = date
      ? moment(new Date(date)).format('YYYY-MM-DD')
      : '';
    this.setState({
      customerFeed,
      preferred_date: date ? date : '',
      isUserDate: true
    });
  };

  getFields(no) {
    const { lang } = this.props.user;
    switch (no) {
      case 2:
        return (
          <Fragment>
            <div className="row">
              {this.state.showDatePicker && this.state.dateMeta.length > 0
                ? this.state.dateMeta.slice(0, 2).map((i, j) => (
                    <div
                      key={j}
                      className="inputGroup left col-md-12 col-xs-24"
                    >
                      <input
                        id={i.date}
                        data-id={i.date}
                        name="ndr-checkbox"
                        className="ndr-checkbox-days"
                        type="radio"
                        checked={
                          this.state.customerFeed.preferred_date === i.date
                            ? true
                            : false
                        }
                        onChange={e => this.dateToggle(e)}
                      />
                      <label className="pre-text" htmlFor={i.date}>{`${
                        i.dayOfWeek
                      } (${moment(i.date).format('DD-MM-YYYY')})`}</label>
                    </div>
                  ))
                : null}
              {this.state.showDatePicker && (
                <div className="inputGroup left col-md-12 col-xs-24">
                  <span
                    className={`${
                      !this.state.preferred_date ? 'custom-calendar' : ''
                    }`}
                  >
                    <DatePicker
                      placeholderText={strings[lang].anyOtherDate}
                      onChange={this.onCalendarChange}
                      selected={this.state.preferred_date}
                      dateFormat="dd-MM-yyyy"
                      isClearable={true}
                      disabledKeyboardNavigation
                      includeDates={this.state.dateMeta
                        .slice(2)
                        .map(item => new Date(item.date))}
                    />
                  </span>
                </div>
              )}
              {this.state.hideCommentsNDRFeedback ? null : (
                <div className="inputGroup left col-md-24 col-xs-24">
                  <label className="text">{strings[lang].comments}</label>
                  <textarea
                    id="comment"
                    className="input-text"
                    rows="4"
                    cols="50"
                    onChange={e => this.changeValue(e)}
                    value={this.state.customerFeed.comment || ''}
                  />
                </div>
              )}
            </div>
          </Fragment>
        );
      case 1:
        return (
          <Fragment>
            <div className="inputGroup left">
              <label className="text">
                {strings[lang].landmark}
                {this.state.customerFeed?.capture?.landmark ? (
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                ) : null}
              </label>
              <input
                id="landmark"
                type="text"
                className="input-text"
                onChange={e => this.searchLandmark(e)}
                value={this.state.customerFeed.landmark || ''}
                maxLength="128"
                disabled={!this.state.customerFeed?.capture?.landmark}
              />
              <div className="pos-rel">
                <ul className="suggestions pos-abs">
                  {this.props.googleSearch.list.map((item, i) => (
                    <li
                      key={i}
                      onClick={e => this.selectedLandmark(e, item.description)}
                    >
                      {item.description}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="inputGroup left">
              <label className="text">
                {strings[lang].address}
                {this.state.customerFeed?.capture?.address ? (
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                ) : null}
              </label>
              <input
                id="address"
                type="text"
                className="input-text"
                onChange={e => this.changeValue(e)}
                value={this.state.customerFeed.address || ''}
                maxLength="255"
                disabled={!this.state.customerFeed?.capture?.address}
              />
            </div>
            <div className="inputGroup left">
              <label className="text">
                {strings[lang].pincode}
                {this.state.customerFeed?.capture?.drop_pincode ? (
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                ) : null}
              </label>
              <input
                id="drop_pincode"
                type="text"
                className="input-text"
                onChange={e => this.changeValue(e)}
                value={this.state.customerFeed.drop_pincode || ''}
                maxLength="6"
                disabled={!this.state.customerFeed?.capture?.drop_pincode}
              />
            </div>
            {this.state.showDatePicker && (
              <div className="inputGroup left">
                <label className="text">
                  {strings[lang].preferredDate}
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                </label>
                <span
                  className={`${
                    !this.state.preferred_date ? 'custom-calendar' : ''
                  }`}
                >
                  <DatePicker
                    onChange={this.onCalendarChange}
                    minDate={new Date()}
                    selected={this.state.preferred_date}
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    disabledKeyboardNavigation
                    includeDates={this.state.dateMeta.map(
                      item => new Date(item.date)
                    )}
                  />
                </span>
              </div>
            )}
          </Fragment>
        );
      case 4:
        return (
          <Fragment>
            <div className="inputGroup left">
              <input
                id="yes"
                name="ndr-checkbox"
                className="ndr-checkbox-call"
                onChange={e => this.alternateNumber(e)}
                type="radio"
              />
              <label className="pre-text" htmlFor="yes">
                {strings[lang].yes}
              </label>
            </div>
            <div className="inputGroup left">
              <input
                id="no"
                name="ndr-checkbox"
                className="ndr-checkbox-call"
                onChange={e => this.alternateNumber(e)}
                type="radio"
              />
              <label className="pre-text" htmlFor="no">
                {strings[lang].no}
              </label>
            </div>
            <div
              id="phone-block"
              className={`inputGroup left ${
                this.state.isAltNumber ? 'show' : 'hide'
              }`}
            >
              <label className="text">
                {strings[lang].provideAltNo}
                {this.state.customerFeed?.capture?.phone_number ? (
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                ) : null}
              </label>
              <input
                id="phone_number"
                type="text"
                className="input-text"
                onChange={e => this.changeValue(e)}
                value={this.state.customerFeed.phone_number || ''}
                disabled={!this.state.customerFeed?.capture?.phone_number}
              />
            </div>
            {this.state.showDatePicker && (
              <div className="inputGroup left">
                <label className="text">
                  {strings[lang].preferredDate}
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                </label>
                <span
                  className={`${
                    !this.state.preferred_date ? 'custom-calendar' : ''
                  }`}
                >
                  <DatePicker
                    onChange={this.onCalendarChange}
                    minDate={new Date()}
                    selected={this.state.preferred_date}
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    disabledKeyboardNavigation
                    includeDates={this.state.dateMeta.map(
                      item => new Date(item.date)
                    )}
                  />
                </span>
              </div>
            )}
          </Fragment>
        );
      case 3:
      case 5:
        return (
          <Fragment>
            {this.state.showDatePicker && (
              <div className="inputGroup left">
                <label className="text">
                  {strings[lang].preferredDate}
                  <span className="mandatory">
                    <strong>*</strong>
                  </span>
                </label>
                <span
                  className={`${
                    !this.state.preferred_date ? 'custom-calendar' : ''
                  }`}
                >
                  <DatePicker
                    onChange={this.onCalendarChange}
                    minDate={new Date()}
                    selected={this.state.preferred_date}
                    dateFormat="dd-MM-yyyy"
                    isClearable={true}
                    disabledKeyboardNavigation
                    includeDates={this.state.dateMeta.map(
                      item => new Date(item.date)
                    )}
                  />
                </span>
              </div>
            )}
            {this.state.hideCommentsNDRFeedback ? null : (
              <div className="inputGroup left">
                <label className="text">{strings[lang].comments}</label>
                <textarea
                  id="comment"
                  className="input-text"
                  rows="16"
                  cols="50"
                  onChange={e => this.changeValue(e)}
                  value={this.state.customerFeed.comment || ''}
                />
              </div>
            )}
          </Fragment>
        );
      case 6:
        return (
          <Fragment>
            <div className="inputGroup left">
              <input
                id="yes"
                name="ndr-checkbox"
                className="ndr-checkbox-call"
                onChange={e => this.askForDelivery(e)}
                type="radio"
              />
              <label className="pre-text" htmlFor="yes">
                {strings[lang].yes}
              </label>
            </div>
            <div className="inputGroup left">
              <input
                id="no"
                name="ndr-checkbox"
                className="ndr-checkbox-call"
                onChange={e => this.askForDelivery(e)}
                type="radio"
              />
              <label className="pre-text" htmlFor="no">
                {strings[lang].no}
              </label>
            </div>
            <div
              className={`inputGroup left ${
                this.state.isRejected === null || !this.state.showDatePicker
                  ? 'hide'
                  : this.state.isRejected
                  ? 'hide'
                  : 'show'
              }`}
            >
              <label className="text">
                {strings[lang].preferredDate}
                <span className="mandatory">
                  <strong>*</strong>
                </span>
              </label>
              <span
                className={`${
                  !this.state.preferred_date ? 'custom-calendar' : ''
                }`}
              >
                <DatePicker
                  onChange={this.onCalendarChange}
                  minDate={new Date()}
                  selected={this.state.preferred_date}
                  dateFormat="dd-MM-yyyy"
                  isClearable={true}
                  disabledKeyboardNavigation
                  includeDates={this.state.dateMeta.map(
                    item => new Date(item.date)
                  )}
                />
              </span>
            </div>
            {this.state.hideCommentsNDRFeedback ? null : (
              <div id="phone-block" className={`inputGroup left`}>
                <label className="text">{strings[lang].comments}</label>
                <textarea
                  id="comment"
                  className="input-text"
                  rows="4"
                  cols="50"
                  onChange={e => this.changeValue(e)}
                  value={this.state.customerFeed.comment || ''}
                />
              </div>
            )}
          </Fragment>
        );
      default:
    }
  }

  renderScreen(no) {
    const { opsFeed, user } = this.props;
    const { lang } = user;
    const { screen, form } = this.state;
    const sanitizer = dompurify.sanitize;
    switch (no) {
      case 1:
        return (
          <div
            className={`col-md-16 col-xs-24 view-box pd-0 ${
              screen === 1 ? 'show' : 'hide'
            } `}
          >
            <div className="header">
              <div className="text-left">
                <p className="pos-rel xs-pd-left-10">
                  {strings[lang].takeAction}{' '}
                  <span
                    className="icon-arrow_back pull-left"
                    role="button"
                    tabIndex="0"
                    onClick={e => this.goBack(e)}
                  />
                </p>
              </div>
            </div>
            <div className="content">
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    this.state.showDatePicker
                      ? strings[lang].ndrBucket(
                          opsFeed.bucket_id,
                          ['rblbank', 'rblbank-test'].includes(user.subdomain)
                        )
                      : strings[lang].ndrBucketNoDate(
                          opsFeed.bucket_id,
                          ['rblbank', 'rblbank-test'].includes(user.subdomain)
                        )
                  )
                }}
              />
              <form className="form basic-form mrg-top-40">
                {this.getFields(opsFeed.issue_id)}
                <div className="text-center mrg-top-20 mrg-btm-10">
                  <button className="button" onClick={this.handleSubmit}>
                    {strings[lang].submit}
                  </button>
                </div>
              </form>
            </div>
            <div
              className="footer text-left pos-rel"
              role="button"
              tabIndex="0"
              onClick={e => this.handleClick(e, 2)}
            >
              <a className="link">{strings[lang].youFacedAnyIssue}</a>
              <span className="icon-navigate_next pull-right" />
            </div>
          </div>
        );
      case 2:
        return (
          <div
            className={`col-md-16 col-xs-24 view-box pd-0 ${
              screen === 2 ? 'show' : 'hide'
            }`}
          >
            <div className="header">
              <div className="text-left">
                <p className="pos-rel xs-pd-left-10">
                  {strings[lang].pleaseTellUs}{' '}
                  <span
                    className="icon-arrow_back pull-left"
                    role="button"
                    tabIndex="0"
                    onClick={e => this.handleClick(e, 1)}
                  />
                </p>
              </div>
            </div>
            <div className="content">
              <p className="fs-14">
                <em>{strings[lang].pressNextBtn}</em>
              </p>
              <form className="form">
                <div
                  className="inputGroup"
                  role="button"
                  tabIndex="0"
                  onClick={e => this.handleRadio(e, 4)}
                >
                  <input
                    id="4"
                    name="ndr-checkbox"
                    className="ndr-checkbox"
                    type="radio"
                    checked={form === 4}
                  />
                  <label className="pre-text" htmlFor="4">
                    {strings[lang].agentDidntCall}
                  </label>
                </div>
                <div
                  className="inputGroup"
                  role="button"
                  tabIndex="0"
                  onClick={e => this.handleRadio(e, 1)}
                >
                  <input
                    id="1"
                    name="ndr-checkbox"
                    className="ndr-checkbox"
                    type="radio"
                    checked={form === 1}
                  />
                  <label className="pre-text" htmlFor="1">
                    {strings[lang].noAttemptToAddress}
                  </label>
                </div>
                <div
                  className="inputGroup"
                  role="button"
                  tabIndex="0"
                  onClick={e => this.handleRadio(e, 2)}
                >
                  <input
                    id="2"
                    name="ndr-checkbox"
                    className="ndr-checkbox"
                    type="radio"
                    checked={form === 2}
                  />
                  <label className="pre-text" htmlFor="2">
                    {strings[lang].wasNotAvailable}
                  </label>
                </div>
                {/* <div className="inputGroup" onClick={e => this.handleRadio(e, 3)}>
                <input id="3" name="ndr-checkbox" className="ndr-checkbox" type="radio" />
                <label className="pre-text" htmlFor="3">Others</label>
              </div> */}
                <div
                  className="inputGroup"
                  role="button"
                  tabIndex="0"
                  onClick={e => this.handleRadio(e, 6)}
                >
                  <input
                    id="6"
                    name="ndr-checkbox"
                    className="ndr-checkbox"
                    type="radio"
                    checked={form === 6}
                  />
                  <label className="pre-text" htmlFor="6">
                    {strings[lang].doNotWantProduct}
                  </label>
                </div>
                <div className="text-center mrg-top-20 mrg-btm-10">
                  <button
                    className="button"
                    disabled={form === 0}
                    onClick={e => this.handleClick(e, 3)}
                  >
                    {strings[lang].next}
                  </button>
                </div>
              </form>
            </div>
          </div>
        );
      case 3:
        return (
          <div
            className={`col-md-16 col-xs-24 view-box pd-0 ${
              screen === 3 ? 'show' : 'hide'
            } `}
          >
            <div className="header">
              <div className="text-left">
                <p className="pos-rel xs-pd-left-10">
                  {strings[lang].takeAction}{' '}
                  <span
                    className="icon-arrow_back"
                    role="button"
                    tabIndex="0"
                    onClick={e => this.handleClick(e, 2)}
                  />
                </p>
              </div>
            </div>
            <div className="content">
              {this.getHeading(form)}
              <form className="form basic-form mrg-top-40">
                {this.getFields(form)}
                <div className="text-center mrg-top-20 mrg-btm-10">
                  <button className="button" onClick={this.handleSubmit}>
                    {strings[lang].submit}
                  </button>
                </div>
              </form>
            </div>
          </div>
        );
      default:
    }
  }

  askForDelivery(e) {
    let { customerFeed, isRejected } = this.state;
    const { id } = e.target;
    if (id === 'no') {
      customerFeed.is_rejected = isRejected = true;
    } else if (id === 'yes') {
      customerFeed.is_rejected = isRejected = false;
    }
    this.setState({ customerFeed, isRejected });
  }

  alternateNumber(e) {
    const { tracking } = this.props;
    let { customerFeed, isAltNumber } = this.state;
    const { id } = e.target;
    if (id === 'no') {
      isAltNumber = true;
      customerFeed.phone_number = '';
    } else if (id === 'yes') {
      isAltNumber = false;
      customerFeed.phone_number = tracking.customer.phone;
    }
    this.setState({ customerFeed, isAltNumber });
  }

  dateToggle(e) {
    const { customerFeed } = this.state;
    customerFeed.preferred_date = e.target.id;
    this.setState({ customerFeed, preferred_date: null, isUserDate: true });
  }

  changeValue(e) {
    const { customerFeed } = this.state;
    customerFeed[e.target.id] = e.target.value;
    this.setState({ customerFeed });
  }

  searchLandmark(e) {
    const { customerFeed } = this.state;
    customerFeed[e.target.id] = e.target.value;
    this.setState({ customerFeed });
    if (e.target.value.length > 2) {
      this.props.fetchGoogleSearchHandler(e.target.value);
    }
  }

  selectedLandmark(e, landmark) {
    const { customerFeed } = this.state;
    customerFeed.landmark = landmark;
    this.setState({ customerFeed });
    this.props.resetGoogleSearchHandler();
  }

  handleSubmit(e) {
    e.preventDefault();
    const phonePattern = new RegExp(/^\d{10}$/);
    const datePattern = new RegExp(/[0-9]{2}[-][0-9]{2}[-][0-9]{4}$/);
    const datePattern2 = new RegExp(/[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/);
    const pincodePattern = new RegExp(/^\d{6}$/);

    const { opsFeed, tracking, user, page } = this.props;
    const { udfKeys } = tracking.theme;
    const { lang } = user;
    const { customerFeed, form, screen, isAltNumber } = this.state;

    let query = {};
    if (form === 1 || (form === 0 && opsFeed.issue_id === 1)) {
      if (
        customerFeed?.capture?.address &&
        (customerFeed.address === null ||
          customerFeed.address === '' ||
          customerFeed.address.trim().length < 10)
      ) {
        return toast.error(strings[lang].addressErr);
      }
      if (
        customerFeed.capture.drop_pincode &&
        (!pincodePattern.test(customerFeed.drop_pincode) ||
          customerFeed.drop_pincode.trim().length < 6)
      ) {
        return toast.error(strings[lang].pincodeErr);
      }
      if (
        customerFeed.capture?.landmark &&
        (customerFeed.landmark === null || customerFeed.landmark === '')
      ) {
        return toast.error(strings[lang].landmarkErr);
      }
      if (
        customerFeed.capture?.landmark &&
        customerFeed.landmark?.trim()?.length < 5
      ) {
        return toast.error(strings[lang].provideLandmark);
      }
      if (
        this.state.showDatePicker &&
        ((!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
          !this.state.isUserDate)
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 1;
      query = (({
        issue_id,
        address,
        drop_pincode,
        landmark,
        preferred_date
      }) => ({ issue_id, address, drop_pincode, landmark, preferred_date }))(
        customerFeed
      );
    } else if (form === 2 || (form === 0 && opsFeed.issue_id === 2)) {
      if (
        this.state.showDatePicker &&
        ((!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
          !this.state.isUserDate)
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 2;
      query = (({ issue_id, preferred_date, comment }) => ({
        issue_id,
        preferred_date,
        comment
      }))(customerFeed);
    } else if (form === 4 || (form === 0 && opsFeed.issue_id === 4)) {
      if (isAltNumber === null) {
        return toast.error(strings[lang].chooseOption);
      }
      if (
        isAltNumber &&
        customerFeed.capture.phone_number &&
        customerFeed.phone_number !== null &&
        customerFeed.phone_number !== ''
      ) {
        const firstChar = Number(customerFeed.phone_number.charAt(0));
        if (firstChar < 6) {
          return toast.error(strings[lang].phoneErr);
        }
      }
      if (
        isAltNumber &&
        customerFeed.capture.phone_number &&
        !phonePattern.test(customerFeed.phone_number)
      ) {
        return toast.error(strings[lang].phoneErr);
      }
      if (
        this.state.showDatePicker &&
        ((!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
          !this.state.isUserDate)
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 4;
      query = (({ issue_id, phone_number, preferred_date }) => ({
        issue_id,
        phone_number,
        preferred_date
      }))(customerFeed);
    } else if (form === 3 || (form === 0 && opsFeed.issue_id === 3)) {
      if (
        this.state.showDatePicker &&
        ((!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
          !this.state.isUserDate)
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 3;
      query = (({ issue_id, comment, preferred_date }) => ({
        issue_id,
        comment,
        preferred_date
      }))(customerFeed);
    } else if (form === 6 || (form === 0 && opsFeed.issue_id === 6)) {
      if (this.state.isRejected === null) {
        return toast.error(strings[lang].chooseOption);
      }
      if (this.state.isRejected === false) {
        if (
          this.state.showDatePicker &&
          ((!datePattern.test(customerFeed.preferred_date) &&
            !datePattern2.test(customerFeed.preferred_date)) ||
            !this.state.isUserDate)
        ) {
          return toast.error(strings[lang].dateErr);
        }
      }
      customerFeed.issue_id = 6;
      if (this.state.isRejected === true) {
        query = (({ issue_id, is_rejected, comment }) => ({
          issue_id,
          is_rejected,
          comment
        }))(customerFeed);
      } else {
        query = (({ issue_id, is_rejected, comment, preferred_date }) => ({
          issue_id,
          is_rejected,
          comment,
          preferred_date
        }))(customerFeed);
      }
    } else if (form === 5 || (form === 0 && opsFeed.issue_id === 5)) {
      if (
        this.state.showDatePicker &&
        ((!datePattern.test(customerFeed.preferred_date) &&
          !datePattern2.test(customerFeed.preferred_date)) ||
          !this.state.isUserDate)
      ) {
        return toast.error(strings[lang].dateErr);
      }
      customerFeed.issue_id = 5;
      query = (({ issue_id, preferred_date }) => ({
        issue_id,
        preferred_date
      }))(customerFeed);
    }
    if (screen === 1) {
      query.intent = '';
    } else {
      query.intent = clickEventDescription(query.issue_id);
    }
    if (page.source) {
      query.feedback_source = page.source;
    }
    if (query.preferred_date) {
      query.preferred_date = moment(query.preferred_date).format('YYYY-MM-DD');
    } else {
      query.preferred_date = moment()
        .add(1, 'd')
        .format('YYYY-MM-DD');
    }
    const { search, hash } = this.props.location;
    const searchParams = new URLSearchParams(search + hash);

    query.security_key =
      searchParams.get('security_key') || searchParams.get('key');
    this.setState({ isSubmit: true });
    const toastId = toast.loading(strings[lang].actionInProgress);
    postConcernHandler(
      tracking.trackingId,
      tracking.config.hasRaisedIssue,
      query
    ).then(res => {
      if (res.meta.success) {
        let message = strings[lang].submittedConcern(tracking.courier.name);
        if (udfKeys?.addressIssueBFSIMessage) {
          message = udfKeys.addressIssueBFSIMessage.replace(/\\n/g, '\n');
        }
        toast.success(message, {
          id: toastId
        });
        this.setState({ isSubmit: false });
        this.props.history.replace({
          pathname: this.props.history.location.pathname,
          search: removeSearchParam(
            this.props.history.location.search,
            'issue_id'
          ),
          state: { isActive: true }
        });
        this.goBack();
      } else {
        this.setState({ isSubmit: false });
        toast.error(res.meta.message, { id: toastId });
      }
    });
  }

  render() {
    const { tracking, user } = this.props;
    const { lang, routePrefix } = user;
    const { screen } = this.state;

    return !tracking.isFetching ? (
      <div
        className={`container-fluid pd-top-40 xs-pd-top-10 pd-btm-40 xs-pd-btm-10 text-left main-container
        ${user.subdomain == '6thstreet' ? 'sixthstreet' : ''}
        ${user.subdomain == '6thstreet-test' ? 'sixthstreet-test' : ''}
        ${user.subdomain == '21kschool' ? 'twentyonekschool' : ''}
        ${
          user.subdomain == '1mg' || user.subdomain == '1mg-test'
            ? 'onemg'
            : user.subdomain
        }`}
      >
        <div className="row col-gutter-130 xs-pd-left-10 xs-pd-right-10">
          <div className="col-md-8 pd-left-20  pd-right-20 xs-pd-0 col-xs-24">
            <div className="bg-white hidden-xs mrg-btm-20">
              <div className="ndr order-info pd-20">
                <div className="header">
                  <img
                    className="mrg-right-10 xs-display-in-block"
                    src="/images/order-icon.svg"
                    alt="order icon"
                  />
                  <span>
                    {' '}
                    {['rblbank', 'rblbank-test'].includes(user.subdomain)
                      ? 'Shipment Details'
                      : strings[lang].orderDetails}
                  </span>
                </div>
                <div className="detailed">
                  <p className="mrg-top-10">
                    <label>{strings[lang].courier}: &nbsp;</label>
                    <span className="highlight">{tracking.courier.name}</span>
                  </p>
                  <div className="row mrg-top-10 xs-mrg-top-5">
                    <div className="col-md-24 col-xs-24 pd-left-15 pd-right-15 xs-mrg-btm-5">
                      <label>{strings[lang].trackingId}: &nbsp;</label>
                      <span className="text user-select">
                        {tracking.waybill}
                      </span>
                    </div>
                    <div
                      className={`col-md-24 col-xs-24 pd-left-15 pd-right-15 mrg-top-10 ${
                        ['rblbank', 'rblbank-test'].includes(user.subdomain)
                          ? 'hide'
                          : 'show'
                      }`}
                    >
                      <label className="pull-left ss">
                        {strings[lang].orderId}: &nbsp;
                      </label>
                      <span className="text user-select">
                        {tracking.orderId ? tracking.orderId : 'NA'}
                      </span>
                    </div>
                  </div>
                  <div className="row mrg-top-10 xs-mrg-top-5">
                    <div className="col-md-24 col-xs-24 pd-left-15 pd-right-15">
                      <p>
                        <span className="rectangle" />
                        <label className="vivid-orange pull-left">
                          {strings[lang].status}: &nbsp;
                        </label>
                        <span className="text user-select">
                          <strong
                            style={{ color: tracking.order.timelineColor }}
                          >
                            {strings[lang].statusOnDateFn(
                              tracking.latestStatus.text,
                              moment(
                                tracking.latestStatus.timestamp.slice(0, 10)
                              ).format('Do MMM YY')
                            )}
                          </strong>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="track-order xs-mrg-btm-10">
              <NavLink
                to={`${routePrefix ? `/${routePrefix}` : ''}/${
                  this.props.history.location.search
                }`}
              >
                {strings[lang].viewTracking}
              </NavLink>
            </div>
          </div>

          {this.renderScreen(screen)}
        </div>
      </div>
    ) : null;
  }
}

Issue.contextTypes = {
  router: PropTypes.object.isRequired
};

Issue.propTypes = {
  tracking: PropTypes.object,
  user: PropTypes.object,
  page: PropTypes.object,
  location: PropTypes.object,
  customerFeed: PropTypes.object,
  opsFeed: PropTypes.object,
  history: PropTypes.object,
  googleSearch: PropTypes.object,
  fetchGoogleSearchHandler: PropTypes.func,
  resetGoogleSearchHandler: PropTypes.func
};

const mapStateToProps = state => ({
  tracking: state.tracking,
  opsFeed: state.opsFeed,
  customerFeed: state.customerFeed,
  user: state.user,
  page: state.page,
  googleSearch: state.googleSearch
});

const mapDispatchToProps = dispatch => ({
  fetchGoogleSearchHandler: keyword => dispatch(googleSearchHandler(keyword)),
  resetGoogleSearchHandler: () => dispatch(resetGoogleSearchHandler())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Issue));
